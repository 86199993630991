export const Const = {
  UNDO_DURATION_DELAY_IN_MS: 7000,
  DEFAULT_OTHER_CODE: 'other',
};

export const enum CollectionNames {
  users = 'users',
  projects = 'projects',
  forms = 'forms',
  responses = 'responses',
  invitations = 'invitations',
  plans = 'plans',
  globalChoiceLists = 'globalChoiceLists',
  uniqueNames = 'uniqueNames',
  pendingThumbnails = 'pendingThumbnails',
  invoices = 'invoices',
  responsesAggregationsPerField = 'responsesAggregationsPerField',
  responsesAggregationsPerUser = 'responsesAggregationsPerUser',
  FormsAggregations = 'formsAggregations',
  ProjectsAggregations = 'projectsAggregations',
  UsersAggregations = 'usersAggregations',
  versions = 'versions',
  //deprecated
  elasticsearchSyncTasks = 'elasticsearchSyncTasks',
  //Jobs
  jobs = 'jobs',
}

export const enum JobCategoryId {
  elasticsearch = 'elasticsearch',
  googleMaps = 'googleMaps',
  email = 'email',
}

export const enum JobQueueName {
  elasticsearchSyncProject = 'SyncProject',
  elasticsearchSyncForm = 'SyncForm',
  elasticsearchSyncDocument = 'SyncDocument',
  elasticsearchSyncAll = 'SyncAll',
  reverseGeoCode = 'reverseGeoCode',
  sendEmailJobs = 'sendEmailJobs',
}
