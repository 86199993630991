export interface Plan {
  id?: string;
  isDefault?: boolean;
  name?: string;
  price?: number;
  features?: {
    [name: string]: PlanOptions;
  };
}

export interface PlanOptions {
  limit?: number;
}

export const trialPlan = {
  id: 'trial',
  default: true,
  name: 'Trial',
  price: 0,
  features: {
    data: {
      limit: 1000,
    },
  },
};

// export const freePlan = {
//   id: 'free',
//   default: true,
//   name: 'Free',
//   price: 0,
//   features: {
//     forms: {
//       limit: 1,
//     },
//     responses: {
//       limit: 1000,
//     },
//     users: {
//       limit: 1000,
//     },
//     collectors: {
//       limit: 1000,
//     },
//     storage: {
//       limit: 1000, // in MB
//     },
//   },
// };
